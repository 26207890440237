import React, { useState, useEffect } from "react"

//import { DisplayQuoter } from "../components/quoter"
//import React, { useRef, useState } from "react"
//import IframeResizer from "iframe-resizer-react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PopupModal from "../components/popupModal"
// import LeadForm from "../components/form"
//import Contactform from "../components/contact"

const RateComparePage = () => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const timeId = setTimeout(() => setShowModal(true), 25000)

    return () => clearTimeout(timeId)
  })

  return (
    <Layout>
      <Seo title="Instant Medigap Rate Quotes" />

      {showModal && <PopupModal />}

      <div className="bg-gray-50 p-2 sm:text-2xl text-slate-800 font-bold leading-8 tracking-tight text-center">
        <p>
          This is a sampling of the Medigap plans we offer. In some markets we
          offer plans that are even more affordable but we can't show them to
          you online. Remember to include your Household Discount for even more
          savings if you're eligible!
        </p>
      </div>
      <div className="bg-indigo-800 h-2"></div>
      {/* <div className="flex flex-col flex-grow basis-0 absolute overflow-x-auto overflow-y-scroll top-0 left-0 right-0 bottom-0">
        <div className="flex-grow basis-0 overflow-hidden min-h-full">
        <DisplayQuoter />
        </div>
      </div> */}
      <div className="relative overflow-hidden mb-0 pb-0">
        <div className="absolute top-0 right-0 z-10"></div>
        <iframe
          className=""
          src="https://insurancetoolkits.com/medsupp/lite/?token=3imyP8NN4N_5G17A12T8BTFLzToSUbsrSA4MqK_W"
          style={{
            border: "none",
            width: "calc(100% + 16px)",
            //height: "100%",
            position: "relative",
            top: "-150px",
            padding: 0,
            margin: 0,
            //width: "1px",
            //minWidth: "100%",
            //minHeight: "800px",
            //minHeight: "100%",
            //height: "2000px",
            //overflow: "visible",
          }}
          //height={this.state.iFrameHeight}
          //width="100%"
          //minHeight={1000}
          height={1800}
          //scrolling="no"
          title="Medigap Quoter"
          //name="iFrameResizer0"
          //frameBorder="0"
        ></iframe>
      </div>
      <div className="text-center">
        <p>
          This quotes are sourced from a third party and we are not responsible
          for their accuracy. We are not licensed in all US States or
          Territories and cannot sell plans where we are not licensed.
        </p>
      </div>
    </Layout>
  )
}

export default RateComparePage

import React, { Fragment, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"

import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import toast, { Toaster } from "react-hot-toast"
import { formv3 } from "./hubspot"

export default function PopupModal() {
  const [open, setOpen] = useState(true)
  const nameInputRef = useRef(null)

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const SignUpSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(1, "Must be longer than 1 characters")
      .max(50, "Must be shorter than 50 characters")
      .required("Required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Must be 10 characters or longer")
      .max(15, "Must be shorter than 15 characters")
      .required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
  })

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={nameInputRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <button className="absolute -top-3 -right-3 flex-shrink-0 flex items-center justify-center h-8 w-8 border-red-100 border-2 rounded-full bg-white hover:bg-red-100">
                  <XIcon
                    className="h-6 w-6 text-red-600 hover:bg-red-100"
                    aria-hidden="true"
                    onClick={() => setOpen(false)}
                  />
                </button>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-semibold text-gray-900"
                      >
                        Want to Find Out the Best Medigap Deal?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-base text-gray-700 leading-tight">
                          For the best deal, call us at{" "}
                          <span className="font-bold">844-565-1957</span> or put
                          your information below and we'll contact you for a no
                          cost, no obligation quote.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <>
                  <Formik
                    initialValues={{
                      firstname: "",
                      phone: "",
                      email: "",
                      message: "ASAP",
                    }}
                    validationSchema={SignUpSchema}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                      const fields = Object.keys(values).map(key => {
                        return {
                          name: key,
                          value: values[key],
                        }
                      })
                      console.log("OnSubmit initial fields value:")
                      console.log(fields)

                      var hurl =
                        "https://api.hsforms.com/submissions/v3/integration/submit/21206617/85583193-7964-48f7-be56-91dd85a7cb3f"
                      formv3(fields, hurl)
                      resetForm()
                      setTimeout(() => {
                        setSubmitting(false)
                      }, 1000)
                      setTimeout(() => {
                        setOpen(false)
                      }, 6000)

                      //setSubmitting(false)
                    }}
                  >
                    {({ isSubmitting, errors, touched, values }) => (
                      <Form className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-col rounded-sm shadow-sm p-2 border">
                        <div className=" -space-y-px">
                          <p className="mb-1 text-base leading-tight font-medium text-gray-700">
                            Your Contact Information:
                          </p>
                          <div className="">
                            <label htmlFor="firstname" className="sr-only">
                              Name:{" "}
                            </label>
                            <Field
                              name="firstname"
                              as="input"
                              placeholder="Name (Required)"
                              type="text"
                              //className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus-outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                              className="apppearance-none rounded-none placeholder-gray-500 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 w-full py-2 pl-7 pr-12 sm:text-sm border border-gray-100"
                              ref={nameInputRef}
                            ></Field>

                            <ErrorMessage
                              name="firstname"
                              component="div"
                              className="text-red-500 italic text-sm field-error"
                            />
                          </div>
                          <div className="">
                            <label htmlFor="phone" className="sr-only">
                              Phone Number:{" "}
                            </label>
                            <Field
                              name="phone"
                              as="input"
                              placeholder="Phone Number (Required)"
                              type="tel"
                              className="apppearance-none rounded-none placeholder-gray-500 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 w-full py-2 pl-7 pr-12 sm:text-sm border border-gray-100"
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="text-red-500 italic text-sm field-error"
                            />
                          </div>

                          {/* <Field name="phone">
                            {({ field, form: { touched, errors }, meta }) => (
                              <div>
                                <input
                                  type="tel"
                                  placeholder="Phone Number"
                                  {...field}
                                />
                                {meta.touched && meta.error && (
                                  <div className="error">{meta.error}</div>
                                )}
                              </div>
                            )}
                          </Field> */}

                          <div className="">
                            <label htmlFor="email" className="sr-only">
                              Email:{" "}
                            </label>
                            <Field
                              name="email"
                              as="input"
                              placeholder="Email Address (Required)"
                              type="email"
                              className="apppearance-none rounded-none placeholder-gray-500 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 w-full py-2 pl-7 pr-12 sm:text-sm border border-gray-100"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-red-500 italic text-sm field-error"
                            />
                          </div>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1 text-base leading-tight font-medium text-gray-700">
                            Best Time to Contact You:
                          </p>
                          <label htmlFor="message" className="sr-only">
                            Best Contact Time:{" "}
                          </label>

                          <Field
                            as="select"
                            name="message"
                            className="focus:ring-indigo-500 focus:border-indigo-500 w-full h-full py-2 pl-7 pr-12 border-transparent text-gray-500 sm:text-sm"
                          >
                            <option value="ASAP">As Soon As Possible</option>
                            <option value="morning">Morning</option>
                            <option value="afternoon">Afternoon</option>
                            <option value="evening">Evening</option>
                          </Field>
                        </div>
                        <button
                          type="submit"
                          // onClick={notify}
                          disabled={isSubmitting}
                          className="mt-5 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          //className="mt-2 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Send
                        </button>
                        <Toaster />

                        <button
                          type="button"
                          className="mt-1 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => setOpen(false)}
                          //ref={cancelButtonRef}
                        >
                          No, I don't want to save money
                        </button>
                        <p className="text-center mt-3 text-xs text-gray-700">
                          This is a solicitation for insurance, a member of our
                          team will contact you.
                        </p>
                      </Form>
                    )}
                  </Formik>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
